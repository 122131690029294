
// Default .button class
.button {
    @extend %button;
    display: inline-block;

    &:hover {
        @extend %hover;
    }

    a {
        color: inherit;
        transition: inherit;
    }

    &-team {
        background-color: $color-primary;
    }

    &.signup {
        background-color: $color-secondary;

        &:hover {
            background-color: $color-primary;
        }
    }

}
