
// Content styles
.block-text,
.biography, 
.component-intro-text {
	line-height: 1.63;

	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;
		letter-spacing: -0.045rem;

		// &.intro {
		// 	margin-top: -0.25rem;
		// 	padding: 0 0 2rem;
		// 	font-family: $font-family-sub;
		// 	font-size: $h5;
		// 	line-height: 1.5;
		// 	letter-spacing: -0.085rem;

		// 	@media #{$mobileXL} {
		// 		font-size: $h4;
		// 	}
		// }
	}

	// reset any <ul> that will appear in a template here
	// maintain vertical rhythm with paragraph margin
	ul[class] {
		@extend %reset-ul;
		margin: 0 0 $paragraph-margin;

		ul {
			@extend %reset-ul;
		}
	}

	// New default <ul> lists
	ul {
		@extend %default-ul;
	}

	// New default <ol> lists
	ol {
		@extend %default-ol;
	}

	.lbmw-anchors {
		list-style: disc !important;
		padding: 0 0 0 $block-padding !important;
	}
}


body:not(.section-home):not(.section-contact):not(.section-library) {
	.block-text,
	.staff-biography, 
	.component-intro-text {
		.content > p:first-child, p.intro, .biography > p:first-child  {
			margin-top: -0.25rem;
			padding: 0 0 0.5rem;
			font-family: $font-family-sub; 
			font-size: $h5;
			line-height: 1.5;
			letter-spacing: -0.085rem;

			@media #{$mobileXL} {
				font-size: $h4;
			}
		}
	}
}