
//==========  List styles  ==========//

// list item styles
%default-list {
    padding: 0 0 0 $block-padding;
    margin: 0 0 $paragraph-margin;
}

%default-ul {
    list-style: disc;
    @extend %default-list;
}

%default-ol {
    list-style: decimal;
    @extend %default-list;
}

// list item reset
%reset-ul {
    list-style: none;
    padding: 0;
    margin: 0;
}


//==========  Buttons and default styles  ==========//

// base button styles
// extend for different button styles
%button {
    display: inline-block;
    border: none;
    // @include uppercase($h6 - .2, $font-black);
    background: $color-secondary;
    color: $color-white;
    padding: $block-padding ($block-padding * 2);
    text-align: center;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 0.06rem;
    font-weight: $font-bold;
    transition: $transition;
}

%hover {
    background: $color-primary;
    color: $color-white;
}

// extend for default .read-more
%read-more {
    @extend %button;

    &:hover {
        @extend %hover;
    }
}

