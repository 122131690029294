
// Default link events
a {
	color: $color-quarternary;
	text-decoration: none;
	transition: all t(link) ease-out;

	&:hover {
		color: $color-tertiary;
	}
}
