
// Body text
@font-face {
	font-family: 'Ysabeau';
	font-style: normal;
	font-display: optional;
	font-weight: $font-normal;
	src: local('Ysabeau 400'),
			url('../../../fonts/ysabeau-400.woff2') format('woff2'),
			url('../../../fonts/ysabeau-400.woff') format('woff');
}

@font-face {
	font-family: 'Ysabeau';
	font-style: normal;
	font-display: optional;
	font-weight: $font-bold;
	src: local('Ysabeau 700'),
			url('../../../fonts/ysabeau-700.woff2') format('woff2'),
			url('../../../fonts/ysabeau-700.woff') format('woff');
}


body {
	color: $color-text;
	font-family: $font-family-body;
	font-size: $font-size-body;
	font-weight: $font-normal;
}
