
// Headings
@font-face {
	font-family: 'Prata';
	font-style: normal;
	font-display: optional;
	font-weight: $font-normal;
	src: local('Prata 400'),
			url('../../../fonts/prata-400.woff2') format('woff2'),
			url('../../../fonts/prata-400.woff') format('woff');
}


h1, h2, h3, h4, h5, h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
	margin: 0 0 $heading-margin;
	color: $color-title;
	font-family: $font-family-title;
	font-weight: $font-normal;
	line-height: 1.4;
}

h1,
.h1 {
	font-size: $h2;

	@media #{$tabletXL} {
		font-size: $h1;
		letter-spacing: 0.04rem;
	}
}

h2,.h2 { font-size: $h2; }
h3,.h3 { font-size: $h3; }
h4,.h4 { font-size: $h4; }
h5,.h5 { font-size: $h5; }
h6,.h6 { font-size: $h6; }
